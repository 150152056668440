import * as React from 'react';
import { gdprConsent } from './gdprConsent';
import { GDPRConsent } from './types';

export const useGdprConsent = (): GDPRConsent => {
  const [consent, setConsent] = React.useState<GDPRConsent | null>(() => gdprConsent.value);

  React.useEffect(() => {
    const subscription = gdprConsent.subscribe(setConsent);
    return gdprConsent.unsubscribe(subscription);
  }, []);

  return consent || { accepted: false, replied: false };
};
