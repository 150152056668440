import * as React from 'react';
import { Group, ThemeIcon, rem } from '@mantine/core';
import { IconStarFilled } from '@tabler/icons-react';

interface StarsProps {
  rating: number;
  max?: number;
  size?: number;
}

export const Stars = (props: StarsProps) => {
  const { rating, max = 5, size = 1.75 } = props;
  const ratings = Array.from({ length: max }).map((_, index) => index < Math.round(rating));

  return (
    <Group gap={0}>
      {ratings.map((filled, index) => (
        <ThemeIcon key={index} size={rem(size * 8)} color={filled ? 'yellow.5' : 'gray.3'}>
          <IconStarFilled />
        </ThemeIcon>
      ))}
    </Group>
  );
};
