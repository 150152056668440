import * as React from 'react';
import clsx from 'clsx';
import { Box, ThemeIcon, Group, Card, Flex, Stack } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';

import { Link, Text } from 'shared/components/atoms';
import * as s from './TileLink.module.css';

interface TileLinkProps {
  url: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  variant?: 'wide' | 'compact';
  showShadow?: boolean;
  className?: string;
}

export const TileLink = (props: TileLinkProps) => {
  const { url, title, description, icon, variant = 'wide', showShadow } = props;
  const isCompact = variant === 'compact';

  return (
    <Card
      component={Link}
      to={url}
      radius="md"
      className={clsx(s.TileLink, { [s.TileLink__shadow]: showShadow })}
    >
      <Flex direction={isCompact ? 'row' : 'column'} align="center" gap={isCompact ? 'md' : 'xs'}>
        {icon}
        {isCompact ? (
          <Stack gap="xs">
            <Group gap={4}>
              <Text ff="heading" fw="bold" fz="lg" nbsp className={s.TileLink__title}>
                {title}
              </Text>
              <ThemeIcon c="inherit" size="sm">
                <IconArrowNarrowRight />
              </ThemeIcon>
            </Group>
            <Text c="gray.8" fz="sm" nbsp>
              {description}
            </Text>
          </Stack>
        ) : (
          <>
            <Box px="md">
              <Text ta="center" ff="heading" fw="bold" fz="lg" nbsp className={s.TileLink__title}>
                {title}
              </Text>
            </Box>
            <Text ta="center" c="gray.8" fz="sm" nbsp>
              {description}
            </Text>
          </>
        )}
      </Flex>
    </Card>
  );
};
