import * as React from 'react';
import { useLocation } from '@reach/router';
import { Dialog, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Text, Button, Link, ButtonOutlined } from 'shared/components/atoms';
import appRoutes from 'shared/consts/urls';
import { analytics } from 'shared/analytics/Analytics';
import { useGdprConsent } from 'shared/analytics/useGdprConsent';

const messages = {
  acceptLabel: 'Zgadzam się',
  rejectLabel: 'Blokuj wszystko',
  info: (
    <>
      Szanujemy Twoją prywatność. Używamy plików cookie, aby zapewnić Ci jak największy komfort
      korzystania ze strony. Twoje dane przetwarzamy zgodnie z naszą{' '}
      <Text
        component={Link}
        to={appRoutes.policy.compose()}
        c="primary"
        fz="inherit"
        underline="hover"
      >
        polityką prywatności
      </Text>
      .
    </>
  ),
};

const usePopup = () => {
  const { replied } = useGdprConsent();
  const [isOpen, { open, close }] = useDisclosure(false);
  const location = useLocation();

  const accept = () => {
    close();
    analytics.accept(location);
  };

  const reject = () => {
    close();
    analytics.reject();
  };

  React.useEffect(() => {
    if (!isOpen && !replied) {
      open();
    }
  }, [replied, isOpen, open]);

  return { accept, reject, isOpen };
};

export const CookiePopup = () => {
  const { isOpen, accept, reject } = usePopup();
  const titleId = React.useId();
  const descId = React.useId();

  return (
    <Dialog
      opened={isOpen}
      size="lg"
      shadow="md"
      withCloseButton={false}
      transitionProps={{ transition: 'pop-bottom-right' }}
      role="dialog"
      aria-labelledby={titleId}
      aria-describedby={descId}
    >
      <Text id={titleId} pb="xs" fz="lg" fw="bold" ff="heading">
        Ciasteczka
      </Text>
      <Text id={descId} pb="md" fz="sm" c="gray.9">
        {messages.info}
      </Text>
      <Group justify="center">
        <Button onClick={accept}>{messages.acceptLabel}</Button>
        <ButtonOutlined onClick={reject}>{messages.rejectLabel}</ButtonOutlined>
      </Group>
    </Dialog>
  );
};
