import * as React from 'react';
import { Box, BoxProps, rem } from '@mantine/core';

interface LineProps extends BoxProps {
  size?: number;
}

export const Line = (props: LineProps) => {
  const { size = 8, ...rest } = props;
  return <Box bg="primary" h={4} w={rem(size * 8)} {...rest} />;
};
