import * as React from 'react';
import clsx from 'clsx';
import {
  Loader,
  ElementProps,
  UnstyledButton,
  UnstyledButtonProps,
  createPolymorphicComponent,
} from '@mantine/core';
import * as s from './ButtonOutlined.module.css';

interface ButtonOutlinedProps
  extends React.PropsWithChildren,
    UnstyledButtonProps,
    ElementProps<'button'> {
  center?: boolean;
  width?: string;
  disableMinWidth?: boolean;
  isLoading?: boolean;
}

export const ButtonOutlined = createPolymorphicComponent<'button', ButtonOutlinedProps>(
  React.forwardRef<HTMLButtonElement, ButtonOutlinedProps>((props, ref) => {
    const { children, className, isLoading, disabled, ...rest } = props;
    return (
      <UnstyledButton
        component="button"
        {...rest}
        ref={ref}
        className={clsx(s.ButtonOutlined, className)}
        disabled={disabled || isLoading}
      >
        {isLoading ? <Loader color="white" size="sm" /> : children}
      </UnstyledButton>
    );
  }),
);
