import * as React from 'react';
import clsx from 'clsx';
import { Box, Stack, rem } from '@mantine/core';

import { assertUnreachable } from 'shared/utils';
import { Text } from 'shared/components/atoms';
import { TitleSubline } from '../TitleSubline';

import * as s from './SectionTitle.module.css';

export interface SectionTitleProps extends React.PropsWithChildren {
  title: string;
  subline: string;
  pb?: number;
  description?: string;
  variant?: 'main' | 'section' | 'main-reversed' | 'section-reversed';
  align?: 'left' | 'center';
}

const useSectionVariant = (variant: NonNullable<SectionTitleProps['variant']>) => {
  switch (variant) {
    case 'main':
      return { sublineTag: 'h1', headingTag: 'h2' } as const;
    case 'main-reversed':
      return { sublineTag: 'p', headingTag: 'h1' } as const;
    case 'section':
      return { sublineTag: 'h2', headingTag: 'h3' } as const;
    case 'section-reversed':
      return { sublineTag: 'h3', headingTag: 'h2' } as const;
    default:
      return assertUnreachable(variant);
  }
};

export const SectionTitle = (props: SectionTitleProps) => {
  const {
    children,
    title,
    subline,
    description,
    variant = 'section',
    align = 'left',
    pb = 0,
  } = props;
  const { headingTag, sublineTag } = useSectionVariant(variant);
  const isCenter = align === 'center';
  return (
    <Box maw={rem(700)} mx={isCenter ? 'auto' : 0}>
      <Stack pb={rem(pb * 8)} gap="xs">
        <TitleSubline align={align} component={sublineTag}>
          {subline}
        </TitleSubline>
        <Text
          component={headingTag}
          className={clsx(s.SectionTitle, { [s.Center]: isCenter })}
          nbsp
        >
          {title}
        </Text>
        {description ? (
          <Text pt="md" ta={isCenter ? 'center' : 'justify'} nbsp>
            {description}
          </Text>
        ) : null}
      </Stack>
      {children}
    </Box>
  );
};
