import * as React from 'react';
import { Container, ContainerProps, Title } from '@mantine/core';

import { Text } from 'shared/components/atoms';

interface BigHeadingProps extends ContainerProps {
  children: React.ReactNode;
  description: string;
}

export const BigHeading = (props: BigHeadingProps) => {
  const { children, description, ...rest } = props;
  return (
    <Container size="md" {...rest}>
      <Title order={2} fz={{ base: '32px', md: '40px' }} ta="center" lh={1.4} pb="lg">
        {children}
      </Title>
      <Container size="sm">
        <Text ff="heading" ta="center" lh={1.6}>
          {description}
        </Text>
      </Container>
    </Container>
  );
};
