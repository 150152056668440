exports.components = {
  "component---src-domain-blog-templates-blog-index-tsx": () => import("./../../../src/domain/blog/templates/Blog/index.tsx" /* webpackChunkName: "component---src-domain-blog-templates-blog-index-tsx" */),
  "component---src-domain-blog-templates-post-index-tsx": () => import("./../../../src/domain/blog/templates/Post/index.tsx" /* webpackChunkName: "component---src-domain-blog-templates-post-index-tsx" */),
  "component---src-domain-recipes-templates-recipe-index-tsx": () => import("./../../../src/domain/recipes/templates/Recipe/index.tsx" /* webpackChunkName: "component---src-domain-recipes-templates-recipe-index-tsx" */),
  "component---src-domain-recipes-templates-recipes-index-tsx": () => import("./../../../src/domain/recipes/templates/Recipes/index.tsx" /* webpackChunkName: "component---src-domain-recipes-templates-recipes-index-tsx" */),
  "component---src-domain-shop-templates-product-index-tsx": () => import("./../../../src/domain/shop/templates/Product/index.tsx" /* webpackChunkName: "component---src-domain-shop-templates-product-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oferta-gotowe-diety-index-tsx": () => import("./../../../src/pages/oferta/gotowe-diety/index.tsx" /* webpackChunkName: "component---src-pages-oferta-gotowe-diety-index-tsx" */),
  "component---src-pages-oferta-swiadoma-dieta-w-ciazy-index-tsx": () => import("./../../../src/pages/oferta/swiadoma-dieta-w-ciazy/index.tsx" /* webpackChunkName: "component---src-pages-oferta-swiadoma-dieta-w-ciazy-index-tsx" */),
  "component---src-pages-oferta-wspolpraca-index-tsx": () => import("./../../../src/pages/oferta/wspolpraca/index.tsx" /* webpackChunkName: "component---src-pages-oferta-wspolpraca-index-tsx" */),
  "component---src-pages-opinie-tsx": () => import("./../../../src/pages/opinie.tsx" /* webpackChunkName: "component---src-pages-opinie-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-sklep-koszyk-tsx": () => import("./../../../src/pages/sklep/koszyk.tsx" /* webpackChunkName: "component---src-pages-sklep-koszyk-tsx" */)
}

