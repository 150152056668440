import * as React from 'react';
import { Group, SimpleGrid } from '@mantine/core';

import { Button, Link } from 'shared/components/atoms';
import { ProductShort } from 'domain/shop/types';
import { ProductCard } from 'domain/shop/components';

const messages = {
  showMore: 'Więcej produktów',
};

interface ProductsGridProps {
  products: ProductShort[];
  showMoreLink?: string;
}

export const ProductsGrid = (props: ProductsGridProps) => {
  const { products, showMoreLink } = props;
  return (
    <>
      <SimpleGrid cols={{ base: 1, xs: 2, sm: 3, md: 4 }} spacing="lg">
        {products.map((product) => (
          <ProductCard key={product.id} {...product} />
        ))}
      </SimpleGrid>
      {!showMoreLink ? null : (
        <Group pt={48} justify="center">
          <Button component={Link} to={showMoreLink} c="white">
            {messages.showMore}
          </Button>
        </Group>
      )}
    </>
  );
};
