import * as React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { IconChevronDown, IconMenu2 } from '@tabler/icons-react';
import { Anchor, Box, Flex, Group, Text, ThemeIcon } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';

import appRoutes from 'shared/consts/urls';
import { Button, ButtonIcon, ContentContainer, Logo } from 'shared/components/atoms';

import { CartIcon, CartDrawer, useCartDrawer } from 'domain/shop/components';
import { RecipesNavCard } from './RecipesNavCard';
import { MobileMenu, useMenuDrawer } from './MobileMenu';
import * as s from './Header.module.css';

const messages = {
  cta: {
    mobile: 'Oferta',
    desktop: 'Zobacz ofertę',
  },
  items: [
    { label: 'Współpraca', url: appRoutes.offer.collab.compose() },
    { label: 'Diety', url: appRoutes.offer.diets.index.compose() },
    { label: 'Blog', url: appRoutes.blog.index.compose() },
  ],
  recipes: 'Przepisy',
};

export const Header = () => {
  const cartDrawer = useCartDrawer();
  const menuDrawer = useMenuDrawer();
  const [scrollPosition] = useWindowScroll();
  const isHeaderBg = scrollPosition.y > 40;

  return (
    <>
      <Box h="100%" pos="relative">
        <div className={clsx(s.header_bg, { [s.header_bg_show]: isHeaderBg })} />
        <ContentContainer h="100%">
          <Group h="100%" justify="space-between" align="center" gap={0}>
            <Text component={Link} to={appRoutes.index.compose()} display="block">
              <Logo />
            </Text>

            <Flex visibleFrom="sm" gap={{ base: 'xs', md: 'lg' }}>
              {messages.items.map(({ label, url }) => (
                <Anchor
                  key={url}
                  component={Link}
                  to={url}
                  activeClassName="active"
                  className={s.header_link}
                  underline="never"
                >
                  {label}
                </Anchor>
              ))}

              <RecipesNavCard>
                <Anchor
                  component={Link}
                  to={appRoutes.recipes.index.compose()}
                  className={s.header_link}
                  activeClassName="active"
                  underline="never"
                >
                  <Group gap={0}>
                    {messages.recipes}
                    <ThemeIcon size="sm" variant="transparent" c="inherit">
                      <IconChevronDown />
                    </ThemeIcon>
                  </Group>
                </Anchor>
              </RecipesNavCard>

              <ButtonIcon onClick={cartDrawer.open} title="Koszyk">
                <CartIcon />
              </ButtonIcon>
            </Flex>

            <Button component={Link} to={appRoutes.offer.collab.compose()} visibleFrom="sm">
              {messages.cta.desktop}
            </Button>

            <Flex hiddenFrom="sm" gap={{ base: 'sm', xs: 'md' }}>
              <Anchor
                component={Link}
                to={appRoutes.offer.collab.compose()}
                className={s.header_link}
                activeClassName="active"
                underline="never"
                fz="sm"
              >
                {messages.cta.mobile}
              </Anchor>

              <ButtonIcon onClick={cartDrawer.open} title="Koszyk">
                <CartIcon />
              </ButtonIcon>

              <ButtonIcon onClick={menuDrawer.open} title="Menu">
                <IconMenu2 />
              </ButtonIcon>
            </Flex>
          </Group>
        </ContentContainer>
      </Box>

      <MobileMenu />
      <CartDrawer />
    </>
  );
};
