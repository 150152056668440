import * as React from 'react';
import { Box, Divider, Group, Stack, rem } from '@mantine/core';

import appRoutes from 'shared/consts/urls';
import { MEALS_ITEMS, OFFER_NAV, MENU_NAV } from 'shared/consts/nav';
import { ContentContainer, Logo, Text, Link, Dot } from 'shared/components/atoms';
import { SocialMedia } from 'shared/components/molecules';
import { FooterLinks } from './FooterLinks';

import * as s from './Footer.module.css';

const messages = {
  copyright: `© ${new Date().getFullYear()} Wszelkie prawa zastrzeżone`,
  description:
    'Dietetyk Patrycja Dziub. Pomoc we wsparciu płodności, w ciąży oraz w walce z tężyczką.',
  bottomLinks: [
    {
      link: appRoutes.terms.compose(),
      label: 'Regulamin',
    },
    {
      link: appRoutes.policy.compose(),
      label: 'Polityka prywatności',
    },
    {
      link: 'mailto:kontakt@jzzr.pl',
      label: 'kontakt@jzzr.pl',
    },
  ],
  menu: [
    {
      title: 'Oferta',
      items: OFFER_NAV,
    },
    {
      title: 'Menu',
      items: MENU_NAV,
    },
    {
      title: 'Przepisy',
      items: MEALS_ITEMS,
    },
  ],
};

export const Footer = () => {
  return (
    <footer className={s.Footer}>
      <ContentContainer>
        <div className={s.Footer__outer}>
          <Stack>
            <Logo />

            <Text visibleFrom="sm" fz="xs" c="gray.7" maw={rem(260)} nbsp>
              {messages.description}
            </Text>
          </Stack>

          <div className={s.Footer__inner}>
            {messages.menu.map(({ title, items }) => (
              <Box key={title} className={s.Footer__links}>
                <FooterLinks title={title} items={items} />
              </Box>
            ))}
          </div>
        </div>

        <Divider color="gray.3" my="lg" />

        <div className={s.Footer__bottom}>
          <div className={s.Footer__info}>
            <Group component="nav" gap="xxs" align="center">
              {messages.bottomLinks.map(({ label, link }, index) => (
                <React.Fragment key={label}>
                  {index !== 0 && <Dot bg="gray.6" />}
                  <Text component={Link} to={link} fz="xs" className={s.Footer__link}>
                    {label}
                  </Text>
                </React.Fragment>
              ))}
            </Group>

            <Text c="gray.6" fz="xs">
              {messages.copyright}
            </Text>
          </div>

          <SocialMedia />
        </div>
      </ContentContainer>
    </footer>
  );
};
