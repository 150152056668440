import * as React from 'react';
import { Group, List, SimpleGrid, ThemeIcon, rem } from '@mantine/core';
import { IconThumbDown, IconThumbUp } from '@tabler/icons-react';

import { Text } from 'shared/components/atoms';
import { SectionTitle } from 'shared/components/molecules';
import { SectionTitleProps } from 'shared/components/molecules/SectionTitle';

interface ProsConsProps extends SectionTitleProps {
  pros: {
    title: string;
    items: string[];
  };
  cons: {
    title: string;
    items: string[];
  };
}

export const ProsCons = (props: ProsConsProps) => {
  const { pros, cons, ...sectionTitleProps } = props;
  return (
    <>
      <SectionTitle pb={3} {...sectionTitleProps} />
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="xl">
        <div>
          <Text component="h3" pb="md" ff="heading" fw="bold">
            {pros.title}
          </Text>
          <List spacing="md">
            {pros.items.map((item) => (
              <List.Item key={item}>
                <Group gap="sm" wrap="nowrap">
                  <ThemeIcon variant="light" size={rem(40)} color="green">
                    <IconThumbUp />
                  </ThemeIcon>
                  <Text>{item}</Text>
                </Group>
              </List.Item>
            ))}
          </List>
        </div>
        <div>
          <Text component="h3" pb="md" ff="heading" fw="bold">
            {cons.title}
          </Text>
          <List spacing="md">
            {cons.items.map((item) => (
              <List.Item key={item}>
                <Group gap="sm" wrap="nowrap">
                  <ThemeIcon variant="light" size={rem(40)} color="red">
                    <IconThumbDown />
                  </ThemeIcon>
                  <Text>{item}</Text>
                </Group>
              </List.Item>
            ))}
          </List>
        </div>
      </SimpleGrid>
    </>
  );
};
