import * as React from 'react';
import { Loader, Stack, ThemeIcon, rem } from '@mantine/core';
import { IconShoppingBag } from '@tabler/icons-react';

import { Text } from 'shared/components/atoms';

const messages = {
  loading: 'Pobieram koszyk...',
  empty: 'Koszyk jest pusty',
};

export const CartStateLoading = () => {
  return (
    <Stack mih={rem(260)} align="center" justify="center" p="xl" gap="sm">
      <Text c="primary">{messages.loading}</Text>
      <Loader color="primary" size="sm" />
    </Stack>
  );
};

export const CartStateEmpty = () => {
  return (
    <Stack mih={rem(260)} align="center" justify="center" p="xl" gap="sm">
      <ThemeIcon size="lg" variant="light">
        <IconShoppingBag />
      </ThemeIcon>
      <Text>{messages.empty}</Text>
    </Stack>
  );
};

export const CartState = {
  Loading: CartStateLoading,
  Empty: CartStateEmpty,
};
