import Client from 'shopify-buy';

import { ID } from 'shared/types';
import { Price } from 'domain/shop/valueObjects/Price';
import { CheckoutProductToAdd, CheckoutSession } from 'domain/shop/types';

export class ShopifyCheckoutMapper {
  static map = new ShopifyCheckoutMapper();

  public toCheckoutSession(checkout: Client.Checkout): CheckoutSession {
    return {
      id: checkout.id as ID,
      isExpired: Boolean(checkout.completedAt),
      checkoutUrl: checkout.webUrl,
      productsSubtotalPrice: new Price(checkout.lineItemsSubtotalPrice.amount),
      subtotalPrice: new Price(checkout.subtotalPrice.amount),
      totalPrice: new Price(checkout.totalPrice.amount),
      products: checkout.lineItems.map(({ id, title, variant, quantity }) => {
        if (!variant) throw new Error(`Missing variant in product: ${title}.`);
        return {
          id: id as ID,
          title,
          // slug: variant.product.handle, TODO: add slug
          quantity: quantity,
          hasOnlyDefaultVariant: variant.title === 'Default Title',
          variant: {
            id: variant.id as ID,
            title: variant.title,
            price: new Price(variant.price.amount),
            selectedOptions: variant.selectedOptions,
            // TODO: replace deprecated src property
            image: variant.image?.src || variant.image.url,
          },
        };
      }),
    };
  }

  public productToLineItem(product: CheckoutProductToAdd): Client.CheckoutLineItemInput {
    return {
      variantId: product.variant.id,
      quantity: 1,
    };
  }
}
