import Client from 'shopify-buy';

import { ID } from 'shared/types';
import { CheckoutProductToAdd } from 'domain/shop/types';
import { CheckoutSession } from 'domain/shop/types';
import { CheckoutProvider } from '../interface';
import { ShopifyCheckoutMapper } from './mapper';

export class ShopifyCheckoutProvider implements CheckoutProvider {
  private _client;

  constructor() {
    const domain = process.env.GATSBY_MYSHOPIFY_URL;
    const storefrontAccessToken = process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN;
    if (domain && storefrontAccessToken) {
      this._client = Client.buildClient({
        domain,
        storefrontAccessToken,
        apiVersion: '2023-04',
      });
    } else {
      throw new Error('Shopify access token or domain is missing.');
    }
  }

  public async getCheckoutSession(checkoutId: ID): Promise<CheckoutSession | null> {
    const checkout = await this._client.checkout.fetch(checkoutId);
    if (checkout) {
      const checkoutSession = ShopifyCheckoutMapper.map.toCheckoutSession(checkout);
      return checkoutSession;
    }
    return null;
  }

  public async createCheckoutSession(): Promise<CheckoutSession> {
    const checkout = await this._client.checkout.create();
    const checkoutSession = ShopifyCheckoutMapper.map.toCheckoutSession(checkout);
    return checkoutSession;
  }

  public async addCheckoutProduct(
    checkoutId: ID,
    product: CheckoutProductToAdd,
  ): Promise<CheckoutSession> {
    const lineItem = ShopifyCheckoutMapper.map.productToLineItem(product);
    const checkout = await this._client.checkout.addLineItems(checkoutId, [lineItem]);
    const checkoutSession = ShopifyCheckoutMapper.map.toCheckoutSession(checkout);
    return checkoutSession;
  }

  public async removeCheckoutProduct(checkoutId: ID, productId: ID): Promise<CheckoutSession> {
    const checkout = await this._client.checkout.removeLineItems(checkoutId, [productId]);
    const checkoutSession = ShopifyCheckoutMapper.map.toCheckoutSession(checkout);
    return checkoutSession;
  }

  public async addCheckoutDiscount(checkoutId: ID, discountCode: string): Promise<CheckoutSession> {
    const checkout = await this._client.checkout.addDiscount(checkoutId, discountCode);
    const checkoutSession = ShopifyCheckoutMapper.map.toCheckoutSession(checkout);
    return checkoutSession;
  }

  public async removeCheckoutDiscount(checkoutId: ID): Promise<CheckoutSession> {
    const checkout = await this._client.checkout.removeDiscount(checkoutId);
    const checkoutSession = ShopifyCheckoutMapper.map.toCheckoutSession(checkout);
    return checkoutSession;
  }
}

export const shopifyCheckoutProvider = new ShopifyCheckoutProvider();
