import * as React from 'react';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { Drawer, Group, Stack, ThemeIcon } from '@mantine/core';

import appRoutes from 'shared/consts/urls';
import { Link, Text } from 'shared/components/atoms';
import { SectionTitle } from 'shared/components/molecules';
import { Cart } from 'domain/shop/components/Cart';
import { useCartDrawer } from './useCartDrawer';

export const CartDrawer = () => {
  const { isOpen, close } = useCartDrawer();

  return (
    <Drawer
      opened={isOpen}
      position="right"
      onClose={close}
      title={
        <>
          <SectionTitle title="Twój koszyk" subline="Sklep" />
          <Link
            to={appRoutes.shop.cart.compose()}
            onClick={close}
            display="inline-block"
            underline="hover"
            mt="xs"
          >
            <Group gap={4}>
              <Text span ff="heading" fw="bold" c="primary">
                Przejdź do koszyka
              </Text>
              <ThemeIcon variant="transparent" size="sm">
                <IconArrowNarrowRight />
              </ThemeIcon>
            </Group>
          </Link>
        </>
      }
      closeButtonProps={{
        title: 'Zamknij',
        'aria-label': 'Zamknij koszyk',
      }}
    >
      <Stack gap="lg">
        <Cart />
      </Stack>
    </Drawer>
  );
};
