import * as React from 'react';
import clsx from 'clsx';
import {
  Box,
  Loader,
  ElementProps,
  UnstyledButton,
  UnstyledButtonProps,
  createPolymorphicComponent,
} from '@mantine/core';
import * as s from './ButtonFilled.module.css';

interface ButtonFilledProps
  extends React.PropsWithChildren,
    UnstyledButtonProps,
    ElementProps<'button'> {
  center?: boolean;
  width?: string;
  disableMinWidth?: boolean;
  isLoading?: boolean;
}

export const ButtonFilled = createPolymorphicComponent<'button', ButtonFilledProps>(
  React.forwardRef<HTMLButtonElement, ButtonFilledProps>((props, ref) => {
    const { children, className, isLoading, disabled, ...rest } = props;
    return (
      <Box pos="relative" style={{ zIndex: 0 }}>
        <UnstyledButton
          component="button"
          {...rest}
          ref={ref}
          className={clsx(s.Button, className)}
          disabled={disabled || isLoading}
        >
          {isLoading ? <Loader type="dots" color="white" size="md" /> : children}
        </UnstyledButton>
        <div className={s.Button__shadow} />
      </Box>
    );
  }),
);
