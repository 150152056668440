import * as React from 'react';
import { Accordion } from '@mantine/core';

import { SectionTitle, type SectionTitleProps } from 'shared/components/molecules';

import * as s from './Fqa.module.css';

const defaultMessages = {
  subline: 'FAQ',
  title: 'Najczęściej zadawane pytania',
};

interface FaqProps extends Partial<SectionTitleProps> {
  items: {
    value: string;
    question: string;
    answer: React.ReactNode;
  }[];
}

export const Faq = (props: FaqProps) => {
  const { items, title, subline, ...sectionTitleProps } = props;

  return (
    <SectionTitle
      pb={3}
      align="center"
      title={title || defaultMessages.title}
      subline={subline || defaultMessages.subline}
      {...sectionTitleProps}
    >
      <Accordion variant="separated">
        {items.map(({ value, answer, question }) => (
          <Accordion.Item key={value} value={value} className={s.Faq__item}>
            <Accordion.Control className={s.Faq__control}>{question}</Accordion.Control>
            <Accordion.Panel>{answer}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </SectionTitle>
  );
};
