import * as React from 'react';
import { Stack } from '@mantine/core';

import { Text, Link } from 'shared/components/atoms';

import * as s from './Footer.module.css';

interface FooterLinksProps {
  title: string;
  items: {
    label: string;
    url: string;
  }[];
}

export const FooterLinks = ({ title, items }: FooterLinksProps) => {
  return (
    <Stack component="nav" gap="xs" align="flex-start">
      <Text fz="sm" fw="bold" ff="heading" c="gray.9">
        {title}
      </Text>
      {items.map(({ label, url }) => (
        <Text key={url} component={Link} to={url} className={s.Footer__link} fz="sm" ff="heading">
          {label}
        </Text>
      ))}
    </Stack>
  );
};
