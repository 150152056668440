import * as React from 'react';
import { Stack } from '@mantine/core';

import { CardLink, Text } from 'shared/components/atoms';
import { ProductShort } from 'domain/shop/types';

interface ProductCardProps extends ProductShort {}

export const ProductCard = (props: ProductCardProps) => {
  const { image, price, title, url } = props;
  return (
    <CardLink to={url}>
      <CardLink.Section>
        <CardLink.Image
          image={image}
          alt={title}
          h={{ base: 300, sm: 260 }}
          objectPosition="center top"
        />
      </CardLink.Section>

      <Stack gap="xs" justify="space-between" h="100%" pt="md">
        <CardLink.Title fz="md" fw="normal" ta="left">
          {title}
        </CardLink.Title>
        <Text fz="xl" fw="bold" ff="heading" c="black" ta="right">
          {price.format()}
        </Text>
      </Stack>
    </CardLink>
  );
};
