import * as React from 'react';
import { Group, Stack, ThemeIcon, rem } from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';

import { Text } from 'shared/components/atoms';

interface Item {
  title: string;
  description: string;
}

interface CheckListProps {
  items: (Item | string)[];
  gap?: number;
  titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

export const CheckList = (props: CheckListProps) => {
  const { items, gap = 3, titleAs = 'p' } = props;

  return (
    <Stack gap={rem(gap * 8)}>
      {items.map((item) =>
        typeof item === 'string' ? (
          <Group key={item} align="center" wrap="nowrap" gap="sm">
            <ThemeIcon color="primary.6">
              <IconCircleCheckFilled />
            </ThemeIcon>
            <Text fz="lg" lh={1.5} dangerouslySetInnerHTML={{ __html: item }} />
          </Group>
        ) : (
          <Stack key={item.title} gap="xxs">
            <Group align="center" gap="sm" wrap="nowrap">
              <ThemeIcon color="primary.6">
                <IconCircleCheckFilled />
              </ThemeIcon>
              <Text component={titleAs} ff="heading" fw="bold" fz="lg" nbsp>
                {item.title}
              </Text>
            </Group>
            <Text lh={1.6} nbsp>
              {item.description}
            </Text>
          </Stack>
        ),
      )}
    </Stack>
  );
};
