import '@mantine/core/styles.css';
import * as React from 'react';

import { Providers, Layout } from './src/shared/components/base';

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
