import * as React from 'react';

import appRoutes from 'shared/consts/urls';
import { IconCalendarUser, IconChecklist, IconChefHat } from '@tabler/icons-react';

export const OFFER_OVERVIEW = [
  {
    url: appRoutes.offer.collab.compose(),
    icon: <IconCalendarUser />,
    title: 'Współpraca',
    description: 'Konsultacje online i dieta dopasowana do Twoich potrzeb.',
  },
  {
    url: appRoutes.offer.diets.index.compose(),
    icon: <IconChecklist />,
    title: 'Gotowe diety',
    description: 'Przejdź na zdrową i odpowiednio zbilansowaną dietę już dziś.',
  },
  {
    url: appRoutes.recipes.index.compose(),
    icon: <IconChefHat />,
    title: 'Zdrowe przepisy',
    description: 'Gotuj sama smacznie i zdrowo z moimi przepisami.',
  },
];
