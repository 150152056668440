import * as React from 'react';

import { Modal as MtModal, em, type ModalProps as MtModalProps } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

interface ModalProps extends MtModalProps, React.PropsWithChildren {}

export const Modal = (props: ModalProps) => {
  const { children, ...rest } = props;
  const isMobile = useMediaQuery(`(max-width: ${em('700px')})`);

  return (
    <MtModal fullScreen={isMobile} padding="lg" radius="md" {...rest}>
      {children}
    </MtModal>
  );
};
