import * as React from 'react';
import clsx from 'clsx';
import { ElementProps } from '@mantine/core';

import { Text, TextProps } from 'shared/components/atoms';

import * as s from './CardLink.module.css';

export const CardLinkTitle = (props: TextProps | ElementProps<'h3'>) => {
  const { children, className, ...rest } = props;
  return (
    <Text
      component="h3"
      fz="xl"
      ff="heading"
      fw="bold"
      ta="center"
      nbsp
      className={clsx(s.CardLink__title, className)}
      {...rest}
    >
      {children}
    </Text>
  );
};
