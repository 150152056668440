import * as React from 'react';

import { useSiteMetadata } from 'shared/hooks';
import social_image from 'assets/images/social_image.png';

export interface SEOProps extends React.PropsWithChildren {
  title?: string;
  description?: string;
  image?: string;
  path?: string;
  noindex?: boolean;
}

export const SEO = (props: SEOProps) => {
  const { children, title, description, image, path = '', noindex = false } = props;
  const {
    url: siteUrl,
    title: siteTitle,
    description: siteDescription,
    keywords,
    twitterNick,
    author,
  } = useSiteMetadata();

  const seo = {
    title: title ? `${title} - ${siteTitle}` : siteTitle,
    shareTitle: title ?? siteTitle,
    description: description ?? siteDescription,
    url: siteUrl + path,
    image: image || siteUrl + social_image,
    twitterNick: `@${twitterNick}`,
    keywords,
    author,
  };

  return (
    <>
      <html lang="pl" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      {noindex && <meta name="robots" content="noindex" />}

      <title>{seo.title}</title>
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="author" content={seo.author} />

      {/* Pinterest auth */}
      <meta name="p:domain_verify" content="0018ba165d8d0e574471bbda043c41b4" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:title" content={seo.shareTitle} />
      <meta name="twitter:site" content={seo.twitterNick} />
      <meta name="twitter:description" content={seo.description} />

      {/* Open Graph */}
      <meta property="fb:app_id" content="221944972911007" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={seo.shareTitle} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:width" content="1910" />
      <meta property="og:image:height" content="1000" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:description" content={seo.description} />

      {children}
    </>
  );
};
