import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { IconTrash } from '@tabler/icons-react';
import { Divider, Group, Image, Loader, Stack } from '@mantine/core';

import { ButtonIcon } from 'shared/components/atoms';
import { Text } from 'shared/components/atoms';
import { cart } from '../../entities/Cart';
import { CheckoutProduct } from '../../types';

import * as s from './CartProduct.module.css';

interface CartProductProps {
  product: CheckoutProduct;
}

export const CartProduct = (props: CartProductProps) => {
  const {
    product: { id, title, variant, hasOnlyDefaultVariant },
  } = props;
  const { mutate: removeProduct, isPending: isDeleting } = useMutation({
    mutationFn: cart.removeProduct.bind(cart),
  });

  const handleRemove = () => {
    removeProduct(id);
  };

  return (
    <>
      <Divider />
      <Group pos="relative" align="flex-start" wrap="nowrap">
        {isDeleting ? (
          <div className={s.CartProduct_overlay}>
            <Loader type="dots" size="lg" color="primary.8" />
          </div>
        ) : null}
        <Image src={variant.image} alt={title} radius="md" className={s.CartProduct_image} />
        <Stack gap="xs">
          <Text ff="heading" fw="bold">
            {title}
          </Text>
          {hasOnlyDefaultVariant ? null : <SelectedOpitions options={variant.selectedOptions} />}
          <Text fw="bold" ff="heading" fz="lg">
            {variant.price.format()}
          </Text>
        </Stack>

        <ButtonIcon
          onClick={handleRemove}
          disabled={isDeleting}
          title="Usuń z koszyka"
          ml="auto"
          size="sm"
        >
          <IconTrash />
        </ButtonIcon>
      </Group>
    </>
  );
};

interface SelectedOpitionsProps {
  options: CheckoutProduct['variant']['selectedOptions'];
}

const SelectedOpitions = (props: SelectedOpitionsProps) => {
  const { options } = props;
  return (
    <div>
      {options.map(({ name, value }) => (
        <Group key={value} wrap="nowrap" gap="xxs">
          <Text visibleFrom="xs" fz="sm" c="gray.8">
            {name}:
          </Text>
          <Text fz="small" c="gray.8">
            {value}
          </Text>
        </Group>
      ))}
    </div>
  );
};
