import * as React from 'react';
import { Flex, FlexProps } from '@mantine/core';

import { Dot, Text } from 'shared/components/atoms';

const tags = ['Ciąża', 'Płodność', 'Tężyczka'];

export const OfferTags = (props: FlexProps) => {
  const { direction = 'row', align, gap } = props;
  const isRow = direction === 'row';
  return (
    <Flex
      direction={direction}
      align={align || (isRow ? 'center' : 'flex-start')}
      gap={gap || (isRow ? 'xs' : 0)}
      {...props}
    >
      {tags.map((tag, index) => (
        <React.Fragment key={tag}>
          {index > 0 && isRow && <Dot bg="dark.5" />}
          <Text ff="heading" fw="bold" fz="sm" c="dark.5">
            {tag}
          </Text>
        </React.Fragment>
      ))}
    </Flex>
  );
};
