import * as React from 'react';
import clsx from 'clsx';
import { Box, type BoxProps } from '@mantine/core';

import * as s from './HeroBg.module.css';

interface HeroBgProps extends BoxProps, React.PropsWithChildren {}

export const HeroBg = (props: HeroBgProps) => {
  const { children, className, ...rest } = props;
  return (
    <Box className={clsx(s.HeroBg, className)} {...rest}>
      {children}
    </Box>
  );
};
