import * as React from 'react';
import clsx from 'clsx';
import { Box } from '@mantine/core';

import { Img, ImgProps } from 'shared/components/atoms';

import * as s from './CardLink.module.css';

export const CardLinkImage = (props: ImgProps) => {
  const { className, ...rest } = props;
  return (
    <Box style={{ overflow: 'hidden' }}>
      <Img radius={0} className={clsx(s.CardLink__image, className)} {...rest} />
    </Box>
  );
};
