import * as React from 'react';
import {
  Text as MtText,
  TextProps as MtTextProps,
  createPolymorphicComponent,
} from '@mantine/core';
import clsx from 'clsx';

import * as s from './Text.module.css';
import { nbspScript } from 'shared/utils';

export interface TextProps extends MtTextProps, React.PropsWithChildren {
  ff?: 'heading';
  nbsp?: boolean;
}

export const Text = createPolymorphicComponent<'p', TextProps>((props: TextProps) => {
  const { children, ff, className, nbsp, ...rest } = props;
  return (
    <MtText className={clsx(className, { [s.Text_heading]: ff === 'heading' })} {...rest}>
      {nbsp && typeof children === 'string' ? nbspScript(children) : children}
    </MtText>
  );
});
